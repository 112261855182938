import App from 'next/app';
import '../styles/main.scss';


class Application extends App {
    render() {
        const { Component, pageProps } = this.props;
        return (
            <>
                <Component {...pageProps} />
            </>
        );
    }
}

export default Application;
